$( document ).ready(function() {
    console.log( "ready!" );
    $('[data-toggle="popover"]').popover()
    $('[data-toggle="tooltip"]').tooltip()
    //AOS.init();
    $(function() {
        var timer;
    
        $(window).resize(function() {
            clearTimeout(timer);
            timer = setTimeout(function() {
                $('main').css("min-height", ($(window).height() - $('.navbar').height()) + "px" ) ;
            }, 40);
        }).resize();
    });
});